import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      gaiaWebsite {
        background {
          url
          placeholder
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Sivua ei löytynyt" />
      <div
        className="height-without-footer"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          layout="fullWidth"
          priority={true}
          src={`/_${data.gaiaWebsite.background.url}`}
          background={data.gaiaWebsite.background.placeholder}
          width={2560}
          alt="Gaia-Travels"
          className="img-fluid bg-image blog-title-image gatsby-image-wrapper dark-overlay"
        />

        <div className="text-center circle-around-text">
          <h1
            className="display-3 font-weight-bold text-shadow"
            style={{ color: "white" }}
          >
            HUPS
          </h1>
          <p
            className="subtitle letter-spacing-4 mb-2 text-shadow"
            style={{ color: "white" }}
          >
            404: Sivua ei löytynyt
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
